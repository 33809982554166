/**
 * @file Emits a `long-press` event from an HTML element.
 * @see https://github.com/john-doherty/long-press-event
 *
 * Usage:
 *   <button
 *     v-emit-long-press="2000"
 *     @long-press="onLongPress"
 *   >
 *     Hold me for 2 seconds!
 *   </button>
 */

import device from '@@/bits/device'
import window from '@@/bits/global'
import type { ObjectDirective } from 'vue'

interface Coordinate {
  clientX: number
  clientY: number
}

interface HTMLElementWithLongPress extends HTMLElement {
  timer: number | null
  clearLongPressTimer: () => void
  startLongPressTimer: (e: PointerEvent | TouchEvent | MouseEvent) => void
  fireLongPressEvent: (e: PointerEvent | TouchEvent | MouseEvent) => void

  startX: number
  startY: number

  mouseDownEvent: 'pointerdown' | 'touchstart' | 'mousedown'
  mouseUpEvent: 'pointerup' | 'touchend' | 'mouseup'
  mouseMoveEvent: 'pointermove' | 'touchmove' | 'mousemove'
  mouseDownHandler: (e: PointerEvent | TouchEvent | MouseEvent) => void
  mouseMoveHandler: (e: PointerEvent | TouchEvent | MouseEvent) => void
}

class LongPressEvent extends Event {
  constructor(e: Event) {
    super('long-press', e)
  }
}

const DEFAULT_LONG_PRESS_DURATION_MS = 550
const MAX_DIFF_X = 10
const MAX_DIFF_Y = 10

const toCoordinate = (e: PointerEvent | TouchEvent | MouseEvent): Coordinate => {
  if (e instanceof MouseEvent || e instanceof PointerEvent) {
    return e
  }
  return e.touches[0]
}

const toLongPressEvent = (e: PointerEvent | TouchEvent | MouseEvent): LongPressEvent => {
  return new LongPressEvent(e)
}

const directive: ObjectDirective<HTMLElementWithLongPress> = {
  bind: (el, binding) => {
    el.clearLongPressTimer = () => {
      if (el.timer != null) {
        clearTimeout(el.timer)
        el.timer = null
      }
    }
    el.startLongPressTimer = (e) => {
      el.clearLongPressTimer()

      const timeout =
        typeof binding.value === 'number' && binding.value > 0 ? binding.value : DEFAULT_LONG_PRESS_DURATION_MS
      el.timer = window.setTimeout(() => {
        el.fireLongPressEvent(e)
      }, timeout)
    }
    el.fireLongPressEvent = (e) => {
      el.dispatchEvent(toLongPressEvent(e))
    }

    el.startX = 0
    el.startY = 0

    const hasPointerEvents =
      'PointerEvent' in window || (typeof window.navigator === 'object' && 'msPointerEnabled' in window.navigator)
    el.mouseDownEvent = hasPointerEvents ? 'pointerdown' : device.touchable ? 'touchstart' : 'mousedown'
    el.mouseUpEvent = hasPointerEvents ? 'pointerup' : device.touchable ? 'touchend' : 'mouseup'
    el.mouseMoveEvent = hasPointerEvents ? 'pointermove' : device.touchable ? 'touchmove' : 'mousemove'
    el.mouseDownHandler = (e) => {
      const { clientX, clientY } = toCoordinate(e)
      el.startX = clientX
      el.startY = clientY
      el.startLongPressTimer(e)
    }
    el.mouseMoveHandler = (e) => {
      const { clientX, clientY } = toCoordinate(e)
      const diffX = Math.abs(el.startX - clientX)
      const diffY = Math.abs(el.startY - clientY)
      if (diffX >= MAX_DIFF_X || diffY >= MAX_DIFF_Y) {
        el.clearLongPressTimer()
      }
    }
  },
  inserted: (el) => {
    el.addEventListener(el.mouseDownEvent, el.mouseDownHandler, true)

    // Listen to events that can clear a pending long press event
    el.addEventListener(el.mouseUpEvent, el.clearLongPressTimer, true)
    el.addEventListener(el.mouseMoveEvent, el.mouseMoveHandler, true)
    el.addEventListener('wheel', el.clearLongPressTimer, true)
    el.addEventListener('scroll', el.clearLongPressTimer, true)
  },
  unbind: (el) => {
    el.clearLongPressTimer()
    el.removeEventListener(el.mouseDownEvent, el.mouseDownHandler, true)
    el.removeEventListener(el.mouseUpEvent, el.clearLongPressTimer, true)
    el.removeEventListener(el.mouseMoveEvent, el.mouseMoveHandler, true)
    el.removeEventListener('wheel', el.clearLongPressTimer, true)
    el.removeEventListener('scroll', el.clearLongPressTimer, true)
  },
}

export { LongPressEvent }
export default directive
